const initialState = {
  outOfState: "",
  bed: "",
  date: "",
  location: "",
  coord: "",
};

export const varReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_OUT_OF_STATE":
      console.log("Setting outOfState to: ", action.payload);
      return { ...state, outOfState: action.payload };
    case "SET_BED":
      console.log("Setting bed to: ", action.payload);
      return { ...state, bed: action.payload };
    case "SET_COORD":
      console.log("Setting coord to: ", action.payload);
      return { ...state, coord: action.payload };
    case "SET_LOCATION":
      console.log("Setting location to: ", action.payload);
      return { ...state, location: action.payload };
    case "SET_DATE":
      console.log("Setting date to: ", action.payload);
      return { ...state, date: action.payload };
    default:
      return state;
  }
};
