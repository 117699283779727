import { useState } from "react";
import { toast } from "react-toastify";

import LoginForm from "../components/LoginForm";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
//import { connect } from "mongoose";

const Login = ({ history }) => {
  const [email, setEmail] = useState("tylerruby@comcast.net");
  const [password, setPassword] = useState("123321");

  const navigate = useNavigate();
  const dispatch = useDispatch();

 const handleSubmit = async (e) => {
  e.preventDefault();
  console.log("SEND LOGIN DATA", { email, password });

  try {
    let res = 0
    //await login({ email, password });
    if (res.data) {
      console.log(
        "SAVE USER RES IN REDUX AND LOCAL STORAGE THEN REDIRECT ===> "
      );
      // save user and token to local storage
      window.localStorage.setItem("auth", JSON.stringify(res.data));
      // save user and token to redux
      dispatch({
        type: "LOGGED_IN_USER",
        payload: res.data,
      });
      // Use navigate instead of history.push
      navigate("/main");
    }
  } catch (err) {
    console.log(err);
    if (err.response.status === 400) toast.error(err.response.data);
  }
};

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-4 offset-md-4">
            <LoginForm
              handleSubmit={handleSubmit}
              email={email}
              setEmail={setEmail}
              password={password}
              setPassword={setPassword}
            />
          </div>
        </div>
      </div>


    </>
  );
};

export default Login;
