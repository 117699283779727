import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

/*global chrome*/

//To do: add gpa field
// Utility to parse query parameters
const getQueryParams = (search) => {
  return search
    .substring(1)
    .split('&')
    .reduce((params, param) => {
      const [key, value] = param.split('=');
      params[key] = value;
      return params;
    }, {});
};

const JobApplicationForm = () => {
  const [formData, setFormData] = useState({
    name: "John Doe",
    phoneNumber: "6194427850",
    city: "San Francisco",
    state: "California",
    address: "123 Main St",
    zip: "90210",
    yearsOfExperience: "5",
    compensation: "180000",
    sponsorshipRequired: false,
    email: "john.doe@gmail.com",
    questions: [{ question: "", answer: "" }],
    fallback: "See Resume",
    fallbacknum: "5"
  });
  const [extensionId, setExtensionId] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const queryParams = getQueryParams(location.search);
    setExtensionId(queryParams.extensionId);
  }, [location.search]);

  const handleChange = (e, index) => {
    if (["question", "answer"].includes(e.target.name)) {
      const list = [...formData.questions];
      list[index][e.target.name] = e.target.value;
      setFormData((prevState) => ({ ...prevState, questions: list }));
    } else if (e.target.name === "sponsorshipRequired") {
      setFormData((prevState) => ({ ...prevState, [e.target.name]: e.target.checked }));
    } else {
      const { name, value } = e.target;
      setFormData((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const handleAddQuestion = () => {
    setFormData({
      ...formData,
      questions: [...formData.questions, { question: "", answer: "" }],
    });
  };

  const handleRemoveQuestion = (index) => {
    const questions = [...formData.questions];
    questions.splice(index, 1);
    setFormData({ ...formData, questions });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      window.location.href = "https://www.linkedin.com";
      if (extensionId) {
        chrome.runtime.sendMessage(
          extensionId,
          { type: "FORM_SUBMITTED", data: formData },
          (response) => {
            console.log("Response from background script:", response);

          }
        );
      } else {
        console.error("Extension ID not found.");
        window.location.href = "https://www.linkedin.com";
      }
    } catch (error) {
      console.error("Error submitting form", error);
      window.location.href = "https://www.linkedin.com";
    }
  };

  return (
    <div className="container">
      <div className="d-flex justify-content-center my-4">
        <img src="/sitelogo.jpg" alt="Company Logo" style={{ width: '500px', height: 'auto' }} />
      </div>
      <h1 className="text-center my-4">Auto Apply Job Application Form</h1>
      
      <h3 className="text-center my-4">The autoapplier will autofill questions with these answers, if its not filled on linkedin</h3>
      <form onSubmit={handleSubmit} className="mx-auto" style={{ maxWidth: "600px" }}>
        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            Name:
          </label>
          <input
            type="text"
            name="name"
            id="name"
            value={formData.name}
            onChange={handleChange}
            className="form-control"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            Email:
          </label>
          <input
            type="text"
            name="email"
            id="email"
            value={formData.email}
            onChange={handleChange}
            className="form-control"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="phoneNumber" className="form-label">
            Phone Number:
          </label>
          <input
            type="text"
            name="phoneNumber"
            id="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            className="form-control"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="city" className="form-label">
            City:
          </label>
          <input
            type="text"
            name="city"
            id="city"
            value={formData.city}
            onChange={handleChange}
            className="form-control"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="state" className="form-label">
            State:
          </label>
          <input
            type="text"
            name="state"
            id="state"
            value={formData.state}
            onChange={handleChange}
            className="form-control"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="address" className="form-label">
            Address:
          </label>
          <input
            type="text"
            name="address"
            id="address"
            value={formData.address}
            onChange={handleChange}
            className="form-control"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="zip" className="form-label">
            Zip Code:
          </label>
          <input
            type="text"
            name="zip"
            id="zip"
            value={formData.zip}
            onChange={handleChange}
            className="form-control"
          />
        </div>        
        <div className="mb-3">
          <label htmlFor="compensation" className="form-label">
            Salary:
          </label>
          <input
            type="text"
            name="compensation"
            id="compensation"
            value={formData.compensation}
            onChange={handleChange}
            className="form-control"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="yearsOfExperience" className="form-label">
            Years of Experience:
          </label>
          <input
            type="text"
            name="yearsOfExperience"
            id="yearsOfExperience"
            value={formData.yearsOfExperience}
            onChange={handleChange}
            className="form-control"
          />
        </div>
        <div className="mb-3 form-check">
          <input
            type="checkbox"
            name="sponsorshipRequired"
            id="sponsorshipRequired"
            checked={formData.sponsorshipRequired}
            onChange={handleChange}
            className="form-check-input"
          />
          <label htmlFor="sponsorshipRequired" className="form-check-label">
            Sponsorship Required
          </label>
        </div>
        <label className="form-label">
          <h4> Add Additional Questions and Answers</h4>
            If a question contains "your phrase" autofill with "your answer":
            <br></br>
            For example, if the question contains "python" autofill with "3"
            <br></br>
            <br></br>
            Question: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  Answer:
        </label>
        {formData.questions.map((q, index) => (
          <div key={index} className="mb-3">
            <div className="input-group">
              <input
                className="form-control"
                type="text"
                name="question"
                placeholder="python"
                value={q.question}
                onChange={(e) => handleChange(e, index)}
              />
              <input
                className="form-control"
                type="text"
                name="answer"
                placeholder="3"
                value={q.answer}
                onChange={(e) => handleChange(e, index)}
              />
              <div className="input-group-append">
                {formData.questions.length > 1 && (
                  <button type="button" className="btn btn-danger" onClick={() => handleRemoveQuestion(index)}>
                    Remove
                  </button>
                )}
                {formData.questions.length - 1 === index && (
                  <button type="button" className="btn btn-primary" onClick={handleAddQuestion}>
                    Add
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}
        <div className="mb-3">
          <label htmlFor="fallback" className="form-label">
          If JimApply doesn't know what to autofill from this form, fill with:
          </label>
          <input
            type="text"
            name="fallback"
            id="fallback"
            value={formData.fallback}
            onChange={handleChange}
            className="form-control"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="fallbacknum" className="form-label">
          If JimApply doesn't know what to autofill from this form, and the answer expects a number, fill with:
          </label>
          <input
            type="text"
            name="fallbacknum"
            id="fallbacknum"
            value={formData.fallbacknum}
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <button type="submit" className="btn btn-primary">
          Submit
        </button>
      </form>

      <p className="text-center mt-3">
        <Link to="/main">Back</Link>
      </p>
    </div>
  );
};

export default JobApplicationForm;
