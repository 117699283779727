import React from 'react';
import { Link, useLocation } from 'react-router-dom';

// Utility to parse query parameters
const getQueryParams = (search) => {
  return search
    .substring(1)
    .split('&')
    .reduce((params, param) => {
      const [key, value] = param.split('=');
      params[key] = value;
      return params;
    }, {});
};

// MainPage component
const MainPage = () => {
  const location = useLocation();
  const queryParams = getQueryParams(location.search);
  const extensionId = queryParams.extensionId;

  return (
    <div className="container">
      <div className="text-center my-4">
        <img src="/sitelogo.jpg" alt="Company Logo" className="mb-3" style={{ width: '800px', height: 'auto' }} />
        <h1>Main Page</h1>
        <p>Welcome to the main page!</p>
      </div>
      <div className="d-flex flex-column align-items-center">
        <Link
          to={`/job-application?extensionId=${extensionId}`}
          className="btn btn-primary mb-2"
        >
          Apply for a Job
        </Link>
        <Link to="/" className="btn btn-secondary">
          Logout
        </Link>
      </div>
    </div>
  );
};

export default MainPage;
