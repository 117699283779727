import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './pages/Login.js';
import Signup from './pages/Register';
import MainPage from "./pages/MainPage";
import JobApplicationForm from "./pages/JobApplicationForm";

// run this with code below
// cd client
// npm start

const App = () => {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/home" element={<MainPage />} />
          <Route path="/job-application" element={<JobApplicationForm />} />
        </Routes>
      </div>
    </Router>
  );
};


export default App;
