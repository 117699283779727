import { combineReducers } from "redux";
import { authReducer } from "./auth";
import { varReducer } from "./vars";

const rootReducer = combineReducers({
  auth: authReducer,
  vars: varReducer,
});

export default rootReducer;
