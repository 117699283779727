import { Link, useNavigate } from 'react-router-dom';

const LoginForm = ({
  handleSubmit,
  email,
  setEmail,
  password,
  setPassword,
}) => (
  <form onSubmit={handleSubmit} className="mt-5">
    <div className="modal-content rounded-4 shadow">
      <div className="modal-header p-5 pb-4 border-bottom-0">
        <h2 className="fw-bold mb-0">Log in</h2>
      </div>
      <div className="p-5 pt-0">
          <div className="form-floating mb-3">
            <input
            type="email"
            className="form-control rounded-3"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
            <label htmlFor="floatingInput">Email address</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="password"
              className="form-control rounded-3"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <label htmlFor="floatingPassword">Password</label>
          </div>
          <button disabled={!email || !password} className="w-100 mb-2 btn btn-lg rounded-3 btn-dark">
            Continue
          </button>
          <p className="text-center mt-3">
              Don't have an account? <Link to="/register">Sign up</Link>
            </p>
          <small className="text-muted">
            By clicking Sign in, you agree to the terms of use.
          </small>
          <hr className="my-4" />
          <h2 className="fs-5 fw-bold mb-3">Or use a third-party</h2>

          <button
            className="w-100 py-2 mb-2 btn btn-outline-primary rounded-3"
            type="submit"
          >
            <svg className="bi me-1" width={16} height={16}>
              <use xlinkHref="#facebook" />
            </svg>
            Sign in with Facebook
          </button>
          <button
            className="w-100 py-2 mb-1 btn btn-outline-secondary rounded-3"
            type="submit"
          >
            <svg className="bi me-1" width={16} height={16}>
              <use xlinkHref="#github" />
            </svg>
            Sign in with Apple
          </button>
      </div>
    </div>
  </form>
  

);

export default LoginForm;